<script setup lang="ts">
import SkuConvertToMysteryKit from "@/views/oms/products/SkuConvertToMysteryKit.vue"
import SkuDetail from "@/views/oms/products/SkuDetail.vue"
import SkuList from "@/views/oms/products/SkuList.vue"

definePage({
  meta: {
    layout: "default",
    subject: "OmsProduct",
    action: "access",
  },
});
</script>

<template>
  <!-- 👉 Fixed Header  -->
  <div>
    <SkuConvertToMysteryKit v-if="$route.query.id && $route.query.action === 'convert-to-mystery-set'" :id="$route.query.id" :type="$route.query.type" />
    <SkuDetail v-else-if="$route.query.id" :id="$route.query.id" />
    <SkuList v-else />
  </div>
</template>
